// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

html {
  // Note: Color palettes are generated from primary: #006e95
  $_palettes: (
    primary: (
      0: #000000,
      10: #001e2c,
      20: #003549,
      25: #004059,
      30: #004c69,
      35: #005979,
      40: #00668a,
      50: #0080ad,
      60: #249bcd,
      70: #4cb6e9,
      80: #7bd0ff,
      90: #c4e7ff,
      95: #e3f3ff,
      98: #f5faff,
      99: #fbfcff,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #0a1e28,
      20: #20333e,
      25: #2b3e49,
      30: #374955,
      35: #425561,
      40: #4e616d,
      50: #677a86,
      60: #8093a0,
      70: #9aaebb,
      80: #b5c9d7,
      90: #d1e5f4,
      95: #e3f3ff,
      98: #f5faff,
      99: #fbfcff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #1d1736,
      20: #322c4c,
      25: #3d3758,
      30: #484264,
      35: #544e70,
      40: #605a7d,
      50: #797297,
      60: #938cb1,
      70: #aea6cd,
      80: #cac1e9,
      90: #e6deff,
      95: #f4eeff,
      98: #fdf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #191c1e,
      20: #2e3133,
      25: #393c3e,
      30: #454749,
      35: #505355,
      40: #5c5f61,
      50: #757779,
      60: #8f9193,
      70: #aaabae,
      80: #c5c6c9,
      90: #e1e2e5,
      95: #f0f1f3,
      98: #f8f9fc,
      99: #fbfcff,
      100: #ffffff,
      4: #0c0f10,
      6: #111416,
      12: #1d2022,
      17: #282a2c,
      22: #333537,
      24: #37393c,
      87: #d9dadc,
      92: #e7e8ea,
      94: #edeef0,
      96: #f3f3f6,
    ),
    neutral-variant: (
      0: #000000,
      10: #161c21,
      20: #2a3136,
      25: #353c41,
      30: #41484d,
      35: #4c5359,
      40: #585f65,
      50: #71787d,
      60: #8b9297,
      70: #a5acb2,
      80: #c0c7cd,
      90: #dce3e9,
      95: #ebf2f8,
      98: #f5faff,
      99: #fbfcff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  );

  $_rest: (
    secondary: map.get($_palettes, secondary),
    neutral: map.get($_palettes, neutral),
    neutral-variant: map.get($_palettes, neutral-variant),
    error: map.get($_palettes, error),
  );
  $_primary: map.merge(map.get($_palettes, primary), $_rest);
  $_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

  $light-theme: mat.define-theme(
    (
      color: (
        theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
    )
  );

  $dark-theme: mat.define-theme(
    (
      color: (
        theme-type: dark,
        primary: $_primary,
        tertiary: $_tertiary,
      ),
    )
  );

  @include mat.all-component-themes($dark-theme);

  main,
  mat-dialog-container {
    @include mat.all-component-colors($light-theme);
  }
}
