@use 'variables';
@use 'garrampa-theme';

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/*
  CUSTOM STYLES
 */
.hide-mobile {
  @media (width < variables.$max-mobile-width) {
    display: none !important;
  }
}

.hide-tablet {
  @media (width >= variables.$max-mobile-width) and (width < variables.$max-tablet-width) {
    display: none !important;
  }
}

.hide-desktop {
  @media (width >= variables.$max-tablet-width) {
    display: none !important;
  }
}

/*
  SNACKBAR
 */
.error-snackbar {
  --mdc-snackbar-container-color: red;
  --mat-mdc-snack-bar-button-color: white;

  button {
    color: white !important;
  }
}

/*
  TABLE STYLES
 */
.table-component-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  garrampa-api-material-table {
    width: 100%;
  }

  mat-card {
    width: 100%;
  }

  button {
    align-self: flex-end;
  }

  .table-action-buttons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-left: auto;
  }
}

table.selectable-table {
  tr:hover:not(.mat-mdc-header-row):not(.mat-mdc-footer-row) {
    background-color: whitesmoke;
    cursor: pointer;
  }
}

.table-container {
  overflow: auto;
  width: 100%;
  box-shadow: none;

  table {
    background: none;

    tbody {
      tr:hover:not(.mat-mdc-header-row) {
        background-color: #e0e0e0 !important;
      }
    }
  }
}

.top-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
}

/*
  EDIT STYLES
 */
.edit-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  max-width: 2000px;
  margin: 0 auto;

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(100%/5, 200px), 1fr));
    column-gap: 2rem;
    row-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.expansion-panel-with-table .mat-expansion-panel-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  button {
    align-self: flex-end;
  }

  garrampa-api-material-table {
    width: 100%;
  }
}

.big-button {
  width: 100% !important;
  height: 50px !important;
}

.customer-logo {
  width: 100%;
  height: 100%;
  max-width: 150px;
  border-radius: 100%;
  border: 0.1px solid #ddd;
  margin-top: 12px;
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.no-image {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(248, 0, 0, 1) 50%,
    rgba(255, 255, 255, 1) 51%,
    rgba(255, 255, 255, 1) 100%
  );
}

.save-warning {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 4px;
  padding: 12px;
  max-width: 600px;

  mat-icon {
    overflow: visible;
  }

  &.red {
    border: 1px solid variables.$warn-color;
  }
}

// DIALOG
.dialog-actions {
  justify-content: flex-end !important;
  padding: 20px !important;
  gap: 12px;
}

.dialog-content.mat-mdc-dialog-content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 0.5rem;
}

// BORDER STYLING FOR SELECTION LISTS
mat-list-option {
  border-radius: 4px !important;
}

mat-selection-list mat-list-item {
  border-radius: 4px !important;
}

mat-divider {
  width: 100%;
  margin: 2rem 0 !important;
  opacity: 0.4;
}
