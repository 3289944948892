:root {
  --max-mobile-width: 576px;
  --max-tablet-width: 768px;
}

// For media queries
$max-mobile-width: 576px;
$max-tablet-width: 768px;

$primary-color: #006e95;
$accent-color: #00c4b3;
$warn-color: #ff0000;
